<template>
  <sample-modal
    overlay-class="modal-opacity-bg overflow-hidden"
    container-class="md:rounded-20 max-h-screen sm-h-fill md:h-1/3 overflow-hidden flex flex-col"
    @close-modal="closeModal()"
  >
    <template #main>
      <div
        class="relative w-screen md:w-96 h-screen md:h-auto bg-woodsmoke3 gap-6 px-8 pt-14 pb-16 flex flex-col items-start overflow-y-auto"
      >
        <button
          id="close-button"
          class="cursor-pointer w-6 h-6 flex items-center justify-center ml-auto absolute top-4 right-4"
          @click="closeModal"
        >
          <i class="icon-close text-24 text-white" />
        </button>
        <p class="uppercase text-grayscale-80 font-semibold text-13-140">
          sort
        </p>
        <form class="space-y-4">
          <div
            v-for="order in orders"
            :key="order.id"
            class="flex items-center"
          >
            <input
              :id="order.id + '-mobile'"
              name="notification-method"
              type="radio"
              :checked="order.id === orderBy.id"
              class="hidden"
              @change="orderBySelected(order)"
            />
            <label
              :for="order.id + '-mobile'"
              class="flex items-center cursor-pointer"
            >
              <span
                class="w-5 h-5 inline-block mr-4 rounded-full border border-grayscale-15 flex-shrink-0"
              ></span>
              {{ order.title }}</label
            >
          </div>
        </form>
      </div>
    </template>
  </sample-modal>
</template>

<script setup lang="ts">
import SampleModal from "@/components/modal/SampleModal.vue";
import { computed } from "vue";

const emit = defineEmits(["close"]);
const router = useRouter();

const orders = [
  {
    id: "priceUp",
    field: "priceStartingFrom",
    direction: "-1",
    title: "Price: High - Low",
  },
  {
    id: "priceDown",
    field: "priceStartingFrom",
    direction: "1",
    title: "Price: Low - High",
  },
];

const defaultOrderBy = {
  id: "succession",
  field: "succession",
  direction: "-1",
  title: "Featured",
};

const orderBy = ref(defaultOrderBy);
const query = computed(() => router.currentRoute.value.query);

orderBy.value =
  orders.find(
    (order) =>
      order.field === query.value.column &&
      order.direction == (query.value.order as string),
  ) || defaultOrderBy;

const orderBySelected = (newValue: {
  id: string;
  field: string;
  direction: string;
  title: string;
}) => {
  orderBy.value = orderBy.value?.id === newValue.id ? defaultOrderBy : newValue;

  router.push({
    query: {
      ...router.currentRoute.value.query,
      column: orderBy.value?.field,
      order: orderBy.value?.direction,
    },
  });
  closeModal();
};

const closeModal = () => emit("close");
</script>
<style>
input[type="radio"]:checked + label span {
  background-color: #f5f5f5;
  box-shadow: 0px 0px 0px 4px #1e1e21 inset;
  border: 1px #f5f5f5 solid;
}
</style>
